import { Order } from "./order.interface";

export interface OtpState {
  isLoading: boolean;
  error: any;
  order: Order | {};
}

export const createOrderInitialState: OtpState = {
  isLoading: false,
  error: null,
  order: {}
};

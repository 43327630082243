import { Payment } from "./payment.interface";
import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";

async function createPayment(payload): Promise<Payment> {
  return (await fetchClient.postData<Payment>(
    getEndpoint(`/payment`),
    payload
  ))!;
}

export const paymentService = {
  createPayment,
};

import { Payment } from "../payment.interface";

export interface CreatePaymentState {
  isLoading: boolean;
  error: any;
  payment: Payment;
}

export const createPaymentInitialState: CreatePaymentState = {
  isLoading: false,
  error: null,
  payment: null,
};

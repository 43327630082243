import { createAction } from "@goodfood/utils";
import { CreateMealPlanSubscriptionSubscriptionDto } from "../meal-plan-subscription.interface";

export type GetMealPlanSubscriptionActions =
  | GetMealPlanSubscriptionAction
  | GetMealPlanSubscriptionSuccessAction
  | GetMealPlanSubscriptionFailureAction;

export const GET_MEAL_PLAN_SUBSCRIPTION_TYPE = "[MEAL PLAN SUBSCRIPTION] Get neal plan subscription";
export type GetMealPlanSubscriptionAction = ReturnType<typeof getMealPlanSubscription>;
export const getMealPlanSubscription = (mealPlanSubscriptionId: string) =>
  createAction(GET_MEAL_PLAN_SUBSCRIPTION_TYPE, { mealPlanSubscriptionId });

export const GET_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE =
  "[MEAL PLAN SUBSCRIPTION] Get neal plan subscription success";
export type GetMealPlanSubscriptionSuccessAction = ReturnType<typeof getMealPlanSubscriptionSuccess>;
export const getMealPlanSubscriptionSuccess = (mealPlanSubscription: CreateMealPlanSubscriptionSubscriptionDto) =>
  createAction(GET_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE, mealPlanSubscription);

export const GET_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE =
  "[MEAL PLAN SUBSCRIPTION] Get neal plan subscription failure";
export type GetMealPlanSubscriptionFailureAction = ReturnType<typeof getMealPlanSubscriptionFailure>;
export const getMealPlanSubscriptionFailure = (error: any) =>
  createAction(GET_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE, error);

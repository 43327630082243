import * as Actions from "./actions";
import { CreateMealPlanSubscriptionState, createMealPlanSubscriptionInitialState } from "./state";

export const createMealPlanSubscriptionReducer = (
  state: CreateMealPlanSubscriptionState = createMealPlanSubscriptionInitialState,
  action: Actions.CreateMealPlanSubscriptionActions
): CreateMealPlanSubscriptionState => {
  switch (action.type) {
    case Actions.CREATE_MEAL_PLAN_SUBSCRIPTION_TYPE:
      return { ...state, isLoading: true };
    case Actions.CREATE_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE:
      return { ...state, isLoading: false };
    case Actions.CREATE_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

import * as CreateMealPlanSubscriptionActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import Router from "next/router";
import { mealPlanSubscriptionService } from "../meal-plan-subscription.service";

export function* createMealPlanSubscriptionSaga({
  payload,
}: CreateMealPlanSubscriptionActions.CreateMealPlanSubscriptionAction) {
  try {
    const mealPlanSubscription = yield call(mealPlanSubscriptionService.createMealPlanSubscription, payload);
    yield put(CreateMealPlanSubscriptionActions.createMealPlanSubscriptionSuccess(mealPlanSubscription));
  } catch (error) {
    yield put(CreateMealPlanSubscriptionActions.createMealPlanSubscriptionFailure(error));
  }
}
export function* createMealPlanSubscriptionSuccessSaga({
  payload,
}: CreateMealPlanSubscriptionActions.CreateMealPlanSubscriptionSuccessAction) {
  Router.push({
    pathname: "/payment",
    query: { ...Router.query, mealPlanSubscriptionId: payload.id },
  });
}
export const CreateMealPlanSubscriptionSagas = [
  takeLatest(CreateMealPlanSubscriptionActions.CREATE_MEAL_PLAN_SUBSCRIPTION_TYPE, createMealPlanSubscriptionSaga),
  takeLatest(
    CreateMealPlanSubscriptionActions.CREATE_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE,
    createMealPlanSubscriptionSuccessSaga
  ),
];

import { createAction } from "@goodfood/utils";
import { Order } from "./order.interface";

export type OtpActions =
  | CreateOrderAction
  | SaveOrderAction
  | CreateOrderSuccessAction
  | CreateOrderFailureAction;

export const CREATE_ORDER_TYPE = "[MEALHAUS] Create Order";
export type CreateOrderAction = ReturnType<typeof createOrder>;
export const createOrder = (order: Order) =>
  createAction(CREATE_ORDER_TYPE, order);

  export const SAVE_ORDER_TYPE = "[MEALHAUS] Save Order";
export type SaveOrderAction = ReturnType<typeof saveOrder>;
export const saveOrder = (order: Order) =>
  createAction(SAVE_ORDER_TYPE, order);

  export const CREATE_ORDER_SUCCESS_TYPE =
  "[MEALHAUS] Create Order success";
export type CreateOrderSuccessAction = ReturnType<typeof createOrderSuccess>;
export const createOrderSuccess = () =>
  createAction(CREATE_ORDER_SUCCESS_TYPE);

export const CREATE_ORDER_FAILURE_TYPE =
  "[MEALHAUS] Create Order failure";
export type CreateOrderFailureAction = ReturnType<typeof createOrderFailure>;
export const createOrderFailure = (error: any) =>
  createAction(CREATE_ORDER_FAILURE_TYPE, error);

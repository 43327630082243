import * as Actions from "./actions";
import { CreatePaymentState, createPaymentInitialState } from "./state";

export const createPaymentReducer = (
  state: CreatePaymentState = createPaymentInitialState,
  action: Actions.CreatePaymentActions
): CreatePaymentState => {
  switch (action.type) {
    case Actions.CREATE_PAYMENT_TYPE:
      return { ...state, isLoading: true };
    case Actions.CREATE_PAYMENT_SUCCESS_TYPE:
      return { ...state, isLoading: false };
    case Actions.CREATE_PAYMENT_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

import { createAction } from "@goodfood/utils";
import { Payment } from "../payment.interface";
import { PaymentResponse } from "../payment-response.interface";

export type CreatePaymentActions =
  | CreatePaymentAction
  | CreatePaymentSuccessAction
  | CreatePaymentFailureAction;

export const CREATE_PAYMENT_TYPE = "[GOOD FOOD SHOP] Create Payment";
export type CreatePaymentAction = ReturnType<typeof createPayment>;
export const createPayment = (payment: Payment) =>
  createAction(CREATE_PAYMENT_TYPE, payment);

export const CREATE_PAYMENT_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Create Payment success";
export type CreatePaymentSuccessAction = ReturnType<
  typeof createPaymentSuccess
>;
export const createPaymentSuccess = (payment: PaymentResponse) =>
  createAction(CREATE_PAYMENT_SUCCESS_TYPE, payment);

export const CREATE_PAYMENT_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Create Payment failure";
export type CreatePaymentFailureAction = ReturnType<
  typeof createPaymentFailure
>;
export const createPaymentFailure = (error: any) =>
  createAction(CREATE_PAYMENT_FAILURE_TYPE, error);

import { Order } from "./order.interface";
import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";

async function createOrder(payload): Promise<Order> {
  return (await fetchClient.postData<Order>(getEndpoint(`/otp-order`),payload))!;
}

export const service = {
  createOrder
};

import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";
import { CreateMealPlanSubscriptionSubscriptionDto } from "./meal-plan-subscription.interface";

async function createMealPlanSubscription(payload): Promise<CreateMealPlanSubscriptionSubscriptionDto> {
  return (await fetchClient.postData<CreateMealPlanSubscriptionSubscriptionDto>(getEndpoint(`/meal-plan-subscription`), payload))!;
}

async function getMealPlanSubscription(id: string): Promise<CreateMealPlanSubscriptionSubscriptionDto> {
  return (await fetchClient.getData<CreateMealPlanSubscriptionSubscriptionDto>(getEndpoint(`/meal-plan-subscription/${id}`)))!;
}

export const mealPlanSubscriptionService = {
  createMealPlanSubscription,
  getMealPlanSubscription
};

import * as OtpActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { service } from "./service";
import Router from "next/router";
import { routes } from "@mealhaus/navigation";


export function* otpSaga({
  payload,
}: OtpActions.CreateOrderAction) {
  try {
    yield call(service.createOrder, payload);
    yield put(OtpActions.createOrderSuccess());
  } catch (error) {
    yield put(OtpActions.createOrderFailure(error));
  }
}

export function* createOtpSuccessSaga() {
  Router.push(routes.OTP_SUCCESS);
}

export const OtpSagas = [
  takeLatest(OtpActions.CREATE_ORDER_TYPE, otpSaga),
  takeLatest(OtpActions.CREATE_ORDER_SUCCESS_TYPE, createOtpSuccessSaga)

];

import * as CreatePaymentActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { paymentService } from "../payment.service";
import Router from "next/router";

export function* createPaymentSaga({
  payload,
}: CreatePaymentActions.CreatePaymentAction) {
  try {
    const payment = yield call(paymentService.createPayment, payload);
    yield put(CreatePaymentActions.createPaymentSuccess(payment));
  } catch (error) {
    yield put(CreatePaymentActions.createPaymentFailure(error));
  }
}
export function* createPaymentSuccessSaga({
  payload,
}: CreatePaymentActions.CreatePaymentSuccessAction) {
  Router.push({
    pathname: "/success",
  });
}
export const CreatePaymentSagas = [
  takeLatest(CreatePaymentActions.CREATE_PAYMENT_TYPE, createPaymentSaga),
  takeLatest(
    CreatePaymentActions.CREATE_PAYMENT_SUCCESS_TYPE,
    createPaymentSuccessSaga
  ),
];

import * as GetMealPlanSubscriptionActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { mealPlanSubscriptionService } from '../meal-plan-subscription.service';
import { CreateMealPlanSubscriptionSubscriptionDto } from '../meal-plan-subscription.interface';

export function* getMealPlanSubscriptionSaga({ payload }: GetMealPlanSubscriptionActions.GetMealPlanSubscriptionAction) {
    try {
        const mealPlanSubscription: CreateMealPlanSubscriptionSubscriptionDto = yield call(mealPlanSubscriptionService.getMealPlanSubscription, payload.mealPlanSubscriptionId);
        yield put(GetMealPlanSubscriptionActions.getMealPlanSubscriptionSuccess(mealPlanSubscription));
    } catch (error) {
        yield put(GetMealPlanSubscriptionActions.getMealPlanSubscriptionFailure(error));
    }
}

export const GetMealPlanSubscriptionSagas = [takeLatest(GetMealPlanSubscriptionActions.GET_MEAL_PLAN_SUBSCRIPTION_TYPE, getMealPlanSubscriptionSaga)];

import { createAction } from "@goodfood/utils";
import { CreateMealPlanSubscriptionSubscriptionDto } from "../meal-plan-subscription.interface";

export type CreateMealPlanSubscriptionActions =
  | CreateMealPlanSubscriptionAction
  | CreateMealPlanSubscriptionSuccessAction
  | CreateMealPlanSubscriptionFailureAction;

export const CREATE_MEAL_PLAN_SUBSCRIPTION_TYPE = "[MEALHAUS SHOP] Create meal plan subscription";
export type CreateMealPlanSubscriptionAction = ReturnType<typeof createMealPlanSubscription>;
export const createMealPlanSubscription = (mealPlan: CreateMealPlanSubscriptionSubscriptionDto) =>
  createAction(CREATE_MEAL_PLAN_SUBSCRIPTION_TYPE, mealPlan);

export const CREATE_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE =
  "[MEALHAUS SHOP] Create meal plan subscription success";
export type CreateMealPlanSubscriptionSuccessAction = ReturnType<typeof createMealPlanSubscriptionSuccess>;
export const createMealPlanSubscriptionSuccess = (mealPlan: CreateMealPlanSubscriptionSubscriptionDto) =>
  createAction(CREATE_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE, mealPlan);

export const CREATE_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE =
  "[MEALHAUS SHOP] Create meal plan subscription failure";
export type CreateMealPlanSubscriptionFailureAction = ReturnType<typeof createMealPlanSubscriptionFailure>;
export const createMealPlanSubscriptionFailure = (error: any) =>
  createAction(CREATE_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE, error);

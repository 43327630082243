import * as Actions from './actions';
import { GetMealPlanSubscriptionState, getMealPlanSubscriptionInitialState } from './state';

export const getMealPlanSubscriptionReducer = (
    state: GetMealPlanSubscriptionState = getMealPlanSubscriptionInitialState,
    action: Actions.GetMealPlanSubscriptionActions
): GetMealPlanSubscriptionState => {
    switch (action.type) {
        case Actions.GET_MEAL_PLAN_SUBSCRIPTION_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_MEAL_PLAN_SUBSCRIPTION_SUCCESS_TYPE:
            return { ...state, isLoading: false, mealPlanSubscription: action.payload };
        case Actions.GET_MEAL_PLAN_SUBSCRIPTION_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};

import { CreateMealPlanSubscriptionSubscriptionDto } from "../meal-plan-subscription.interface";

export interface GetMealPlanSubscriptionState {
    isLoading: boolean;
    error: any;
    mealPlanSubscription: CreateMealPlanSubscriptionSubscriptionDto
}

export const getMealPlanSubscriptionInitialState: GetMealPlanSubscriptionState = {
    isLoading: false,
    error: null,
    mealPlanSubscription: null
};

